import React, { useState, useEffect, useRef } from "react"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"

const CustomCarousel = ({ children, autoPlaySpeed, items, showDots, numOfItemsToShow, containerClass = "carousel-container" }) => {
    let stopAutoLoopOnce = false;
    const carousel = useRef(null);

    useEffect(() => {
        const autoLoop = setInterval(() => {
            if (stopAutoLoopOnce) {
                stopAutoLoopOnce = false;
            } else {
                if (carousel.current.state.currentSlide === items.length - carousel.current.state.slidesToShow) {
                    carousel.current.goToSlide(0);
                } else {
                    carousel.current.next();
                }
            }
        }, autoPlaySpeed || 3000);
        return () => clearInterval(autoLoop);
    }, []);

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: numOfItemsToShow[0],
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: numOfItemsToShow[1]
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: numOfItemsToShow[2]
        }
    };

    const CustomDot = ({ onClick, ...rest }) => {
        const {
            onMove,
            index,
            active,
            carouselState: { currentSlide, deviceType }
        } = rest;

        const carouselItems = items.map(x => (
            <div style={{ width: "18px", height: "5px" }}></div>
        ));

        return (
            <button
                className={`${active ? "bg-primary" : "bg-secondary"} mx-1`}
                onClick={() => {
                    stopAutoLoopOnce = true;
                    onClick();
                }}
            >
                {React.Children.toArray(carouselItems)[index]}
            </button>
        );
    };

    return (
        <Carousel
            ref={carousel}
            responsive={responsive}
            arrows={false}
            containerClass={containerClass}
            dotListClass="custom-dot-list-style"
            showDots={showDots}
            customDot={<CustomDot />}
        >
            {children}
        </Carousel>
    );
}

export default CustomCarousel;